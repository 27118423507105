<template>
  <v-card
    flat
  >
    <v-form ref="form">
      <v-card-title
        v-if="confirmar"
        class="d-flex justify-center"
        :style="{ wordBreak: 'break-word' }"
      >
        {{ titulo }}
      </v-card-title>
      <v-card-subtitle
        v-if="confirmar"
        class="d-flex justify-center"
        :style="{ wordBreak: 'break-word' }"
      >
        Dados de identificação e autorização
      </v-card-subtitle>
      <div class="d-flex flex-column justify-space-between">
        <v-row class="pa-4">
          <v-col
            sm="12"
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="login"
              label="Login"
              disabled
            />
          </v-col>
          <v-col
            sm="12"
            md="4"
            cols="12"
          >
            <v-text-field
              :value="`${inst} - ${nomeInst}`"
              label="Empresa"
              disabled
            />
          </v-col>
          <v-col
            sm="12"
            md="4"
            cols="12"
          >
            <v-autocomplete
              v-model="loja"
              :items="lojas"
              label="Loja"
            />
          </v-col>
          <v-col
            v-if="( (titulo.toLowerCase().includes('produto')) && (1==2) )"
            sm="12"
            md="2"
            cols="12"
          >
            <v-text-field
              v-model="horario"
              type="time"
              label="Horário de importação"
              :rules="rules.requirement"
              @blur="identficaBlur"
            />
          </v-col>
          <v-col
            v-if="(titulo.toLowerCase().includes('produto'))"
            sm="12"
            md="10"
            cols="12"
          >
            <v-text-field
              v-model="descricao"
              label="Descrição do lote"
              :rules="rules.requirement"
              @blur="identficaBlur"
            />
          </v-col>
          <v-col
            v-if="(titulo.toLowerCase().includes('cliente'))"
            sm="12"
            md="12"
            cols="12"
          >
            <v-text-field
              v-model="descricao"
              label="Descrição do lote"
              :rules="rules.requirement"
              @blur="identficaBlur"
            />
          </v-col>          
          <v-col
            sm="12"
            md="12"
            cols="12"
          >
            <v-textarea
              v-model="observacao"
              filled
              @blur="identficaBlur"
            >
              <template v-slot:label>
                <span :style="{ color: 'var(--v-primary-base)' }">
                  Observação <small>(opcional)</small>
                </span>
              </template>
            </v-textarea>
          </v-col>
          <v-row
            v-if="confirmar"
            no-gutters
            class="pa-4"
          >
            <v-col>
              <v-btn
                depressed
                color="primary"
                @click="download"
              >
                {{ $t("TelaImportacao.download") }} ({{ versao }})
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                depressed
                color="primary"
                @click="confirmaIdentificacao"
              >
                {{ $t("TabelaImportada.proximo") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    confirmar: {
      type: Boolean,
      required: false,
      default: true,
    },
    dados: {
      type: Object,
      required: true,
      default: () => {return {}},
    },
    arquivo: {
      type: String,
      required: false,
      default: "",
    },
    nomeArquivo: {
      type: String,
      required: false,
      default: "SAAS - Modelo importação de produto (v.2.0.0).xlsx",
    },
    titulo: {
      type: String,
      required: false,
      default: "",
    },  
    versao: {
      type: String,
      required: false,
      default: "",
    },  

  },
  data() {
    return {
      horario: "02:00",
      observacao: "",
      descricao: "",
      dataUser: [],
      lojas: [],
      loja: 19,
      codLote: 1,
      login: "",
      inst: "",
      nomeInst: "",
      rules:{ 
        requirement: [(val) => !!val || "O campo deve ser preenchido"],
      },
    };
  },
  watch: {
    dados: {
      immediate: true,
      handler (val){
        if(!this.confirmar){
          this.descricao = val.desc;
          this.observacao = val.obs;
          this.horario = val.hora
        }
      },
    },
  },
  async mounted() {
    const data = await this.$wso2.getUserData();
    this.dataUser = data;
    this.login = data.base.us01_nm_login;
    this.inst = this.separarUltimoDigitoComHifen(data.base.ce040_nr_inst);
    this.nomeInst = data.base.ce040_nm_fantas;
    this.mapLoja(data.lojas);
    const padroes = await this.getSessionStorage("padroes");
    this.loja = padroes.data.us11_nr_agenpd;
    this.$emit("lojas", this.lojas)
  },

  methods: {
    confirmaIdentificacao(){
      if (this.$refs.form.validate()){
        const obj = {
          loja: this.loja,
          hora: this.horario,
          desc: this.descricao.toUpperCase(),
          obs: this.observacao.toUpperCase(),
        }
        this.$emit('identificar', obj)
      }
    },

    mapLoja(lojas) {
      this.lojas = lojas.map((loja) => {
        return {
          ...loja,
          text: `${this.separarUltimoDigitoComHifen(loja.ce045_nr_loja)} - ${loja.ce045_nm_fantas}`,
          value: loja.ce045_nr_loja,
        };
      });
    },

    identficaBlur(){
      if (!this.confirmar){
        if (this.$refs.form.validate()){
          const obj = {
            hora: this.horario,
            desc: this.descricao.toUpperCase(),
            obs: this.observacao.toUpperCase(),
          }
          this.$emit('identificar', obj)
        }
        return
      } else {
        return
      }
    },

    download() {
      let element = document.createElement("a");
      element.setAttribute("href", this.arquivo);
      element.setAttribute("download", this.nomeArquivo);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    separarUltimoDigitoComHifen(codigo) {
      // Convertendo o código para uma string
      let codigoString = codigo.toString();
  
      // Separando o último dígito
      let ultimoDigito = codigoString.slice(-1);
  
      // Removendo o último dígito do código original
      let codigoSemUltimoDigito = codigoString.slice(0, -1);
  
      // Adicionando o hífen entre o código sem o último dígito e o último dígito
      let codigoSeparado = codigoSemUltimoDigito + '-' + ultimoDigito;
  
      return codigoSeparado;
    },
  },
};
</script>

<style>
.cardIdentify {
  user-select: none;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
</style>
<template>
  <div>
    <v-col class="d-flex justify-center">
      <v-card
        class="dropZone transition-all"
        :style="{ width: '50vw', height: '30vh' }"
        flat
        @dragenter="dragging = true"
        @dragleave="dragging = false"
      >
        <div
          class="dropZone-info"
          @drag="uploadArquivo"
        >
          <div class="dropZone-info-texts">
            <v-icon
              color="primary"
              size="56"
            >
              cloud_upload
            </v-icon>
            <span class="dropZone-title">
              Arraste o arquivo
              <br>
              ou <br>
              Clique para fazer upload
            </span>
            <v-progress-linear
              v-if="importing"
              :style="{ width: '30vw' }"
              color="primary"
              :value="loadingProgress"
              height="10"
            />
          </div>
        </div>
        <input
          accept=".xlsx"
          type="file"
          @change="uploadArquivo"
        >
      </v-card>
    </v-col>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
export default {
  props: {
    tipoArquivoXLSX: {
      type: String,
      required: false,
      default: "produto",
    },
  },  

  data() {
    return {
      file: "",
      filelist: [],
      dragging: false,
      importing: false,
      loadingProgress: 0,
    };
  },

  computed: {
    extension() {
      return this.file ? this.file.name.split(".xlsx").pop() : "";
    },
  },

  methods: {
    async uploadArquivo(file) {
      try {
        this.importing = true;
        // this.$refs.batch.openUploadDrawer();
        const arquivo = file.target.files[0];
        const arr = await readXlsxFile(arquivo);
        const dadosPlanilha = [];

        arr.forEach((item, i) => {
          if (i == 0) {
            this.addProgress(700, 30);
          }
          if (this.tipoArquivoXLSX == "produto") {
            if (i > 4) {
              dadosPlanilha.push({
                itemKey: i,
                barras: item[1],
                baraux: item[2],
                sku: this.trimUpper(item[3]),
                ref: this.trimUpper(item[4]),
                prod: this.trimUpper(item[5]),
                situac: item[6] || true, // Se não vier preenchido, força como ativo
                fabric: item[7],
                ds_grp: this.trimUpper(item[8]),
                ds_subgrupo: this.trimUpper(item[9]),
                ds_depto: this.trimUpper(item[10]),
                ds_secao: this.trimUpper(item[11]),
                ds_colec: this.trimUpper(item[12]),
                ds_categ: this.trimUpper(item[13]),
                ds_marca: this.trimUpper(item[14]),
                tp_potica: item[15],
                fator: item[16],
                mf: item[17],
                unidade: this.trimUpper(item[18]) || 'PC', // Se não vier preenchido, força como PC
                origem: item[19],
                ncm: item[20],
                exncm: item[21],
                custo: item[22],
                venda: item[23],
                venant: 0,
                status: 0, // 0 - novo / 1 - atualizar
              });
            }
          } else if (this.tipoArquivoXLSX == "cliente") {
            if (i > 4) {
              dadosPlanilha.push({
                itemKey: (i-4),
                nr_client: item[1],
                tp_pessoa: item[2],
                nr_cpfcnpj: item[3],
                ds_insest: item[4],
                id_isento: item[5],
                cd_situac: (item[6] || 1),
                nm_client: this.trimUpper(item[7]),
                nm_fantas: this.trimUpper(item[8]),
                dt_nasccl: item[9],
                nm_end: this.trimUpper(item[10]),
                nr_end: this.trimUpper(item[11]),
                ds_compl: this.trimUpper(item[12]),
                nm_bairro: this.trimUpper(item[13]),
                nm_cidade: this.trimUpper(item[14]),
                sg_estado: this.trimUpper(item[15]),
                nr_cep: item[16],
                nr_dddtel: item[17],
                nr_tel: item[18],
                nr_dddcel: item[19],
                nr_cel: item[20],
                id_malad: item[21],
                nm_email: this.trimLower(item[22]),
                dt_inccad: item[23],
                dt_atucad: item[24],
                situacao: "",
              });
            }
          }
        });
        this.addProgress(800, 70);
        this.$emit("importXLSX", dadosPlanilha);
      } finally {
        this.importing = false;
      }
    },

    trimUpper(val) {
      const campo =
        val != null && val != undefined
          ? val.toString().trim().toUpperCase()
          : null;
      return campo;
    },

    addProgress(tempo, quantidade) {
      for (let i = 0; i < quantidade; i++) {
        setTimeout(() => {
          this.loadingProgress++;
          if (this.loadingProgress == 100) {
            setTimeout(() => {
              this.$emit("loaded");
            }, 500);
          }
        }, tempo);
      }
    },
  },
};
</script>

<style>
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.dropZone {
  width: 80%;
  height: 200px;
  border: 2px dashed #ccc !important;
  border-radius: 2rem !important;
}

.dropZone:hover {
  border: 2px solid var(--v-primary-base) !important;
  background-color: #f162361c;
}

.dropZone:hover .dropZone-title {
  color: var(--v-primary-base);
}

.dropZone-info {
  color: #a8a8a8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dropZone-info-texts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>